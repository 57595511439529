import React from 'react';
import { Page, Text, View, Document, StyleSheet } from '@react-pdf/renderer';

const styles = StyleSheet.create({
  page: { padding: 30 },
  section: { margin: 10, padding: 10 },
  header: { fontSize: 18, marginBottom: 20 },
  content: { fontSize: 12, marginBottom: 10 },
});

const AssessmentPDF = ({ assessment, report }) => (
  <Document>
    <Page size="A4" style={styles.page}>
      <View style={styles.section}>
        <Text style={styles.header}>Assessment Report</Text>
        <Text style={styles.content}>Organization: {assessment.orgName}</Text>
        <Text style={styles.content}>Sector: {assessment.orgSector}</Text>
        <Text style={styles.content}>Size: {assessment.orgSize}</Text>
      </View>
      <View style={styles.section}>
        <Text style={styles.header}>Analysis</Text>
        <Text style={styles.content}>{report}</Text>
      </View>
    </Page>
  </Document>
);

export default AssessmentPDF;
